import React from "react"

import Layout from "../components/layout"

import HeaderOffset from "../components/header/headeroffset"
import ColumnContent from "../components/columncontent"
import FAQEntry from "../components/faqentry"

import * as styles from '../styles/pages/certified.module.css'

import image_lead from '../images/certified/lead.png'
/*
import image_greenlabel from '../images/certified/greenlabel.png'
import image_divider from '../images/divider.jpg'
*/
import image_homesafety from '../images/faq/safety.jpg'



const CertifiedPage = () => (
  <Layout activePath='certified' pageTitle="Certified Safe" pageDescription="Protect your home and family from disease-causing pests. Your family is in safe hands with BUG OFF.">
  	<HeaderOffset />
	<div className="margin-content">
		<div className={styles.bannerarea}>
			<img alt='' src={image_homesafety}  className="fullblock" />
			<div className={styles.bannertext}>
				<h2 className={"font-size-large "+styles.maintitle}>
					HEALTH AND SAFETY FAQs
				</h2>
				<div>
					Protect your home and family from disease-causing pests.<br/>
					Your family is in safe hands with BUG OFF&trade;!
				</div>
			</div>
		</div>
	  	<FAQEntry>
	  		<h3 className="font-size-regular">Is BOYSEN<span className="registeredicon">&reg;</span> BUG OFF&trade; eco-friendly?</h3>
	  		<p>
		  		BOYSEN<span className="registeredicon">&reg;</span> BUG OFF&trade; meets the standards for eco-friendly paints. It has been certified by the Singapore Green Label Program as an “Environmentally Preferred Paint” due to its low-VOC content and as a Lead Safe Paint by IPEN because the paint meets the standard for minimal to zero lead and mercury content.
		  	</p>
	  	</FAQEntry>
	  	<hr/>
	  	<FAQEntry>
	  		<h3 className="font-size-regular">Is BOYSEN<span className="registeredicon">&reg;</span> BUG OFF&trade; safe for humans?</h3>
	  		<p>
				BOYSEN<span className="registeredicon">&reg;</span> BUG OFF&trade; does not contain organophosphates but instead uses pyrethroids, insecticidal compounds similar to pyrethrins produced by chrysanthemums to protect themselves from insects. It uses the lowest amount of such compounds possible to make it safe for humans while still being effective in exterminating insect pests. Furthermore, because the crystals are embedded in the dry paint film and are not released into the air, they cannot be inhaled by humans.
			</p>
			<br/>
			<p>
				The wet paint is also safe and non-irritating for the skin, as proven in dermal tests conducted by the laboratories of UP Los Baños.
			</p>
	  	</FAQEntry>
	  	<hr/>
	  	<FAQEntry>
	  		<h3 className="font-size-regular">Is BOYSEN<span className="registeredicon">&reg;</span> BUG OFF&trade; safe to be used on a baby’s or infant’s room?</h3>
	  		<p>
	  			Yes, just as how it is safe to be used on other parts of the house. It is safe for humans and does not release any harmful pesticides into the air. Like with any painting project, make sure that the paint has thoroughly dried and the painted room has been properly ventilated before moving in.
	  		</p>
	  	</FAQEntry>
	  	<hr/>
	  	<FAQEntry>
	  		<h3 className="font-size-regular">Is BOYSEN<span className="registeredicon">&reg;</span> BUG OFF&trade; safe for asthmatics?</h3>
	  		<p>
	  			BOYSEN<span className="registeredicon">&reg;</span> BUG OFF&trade; has not been clinically tested to be safe for asthmatics. As a general precaution, people with asthma are advised to stay away from places where painting is going on.
	  		</p>
	  	</FAQEntry>
	  	<hr/>
	  	<FAQEntry>
	  		<h3 className="font-size-regular">Is BOYSEN<span className="registeredicon">&reg;</span> BUG OFF&trade; safe for pets?</h3>
	  		<p>
	  			BOYSEN<span className="registeredicon">&reg;</span> BUG OFF&trade; is safe for mammals and reptilian pets. However, as a safety measure, keep all pet insects or arachnids away from BUG OFF&trade; painted walls. The insecticidal compound is also toxic to aquatic creatures in large doses and should therefore be used with caution around fishponds and aquariums.
	  		</p>
			<br/>
	  		<p>
	  			For extra precaution, secure ponds and aquariums during painting in order to prevent accidental paint spills.
	  		</p>
	  	</FAQEntry>
	</div>
	  	<br/>
	  	<hr/>
	<div className="margin-content text-centered">
		<h2 className="font-size-large text-centered margin-title">
			CERTIFICATION
		</h2>
		<ColumnContent>
			<div className={`text-centered ${styles.certificationlogo}`}>
				<img src={image_lead} alt="Lead Safe Paint" />
			</div>
			<div className={styles.certificationinfo}>
				<p>
					This certification, issued by international non-government organization IPEN, enables customers to know with confidence that the paints that they are purchasing meet the world’s strictest regulatory standard for lead content in paints.
				</p>
				<br/>
				<p>
					All certified products are tested to contain less than 0.009% total lead (dry weight). This makes the paints safe to use; keeping painters, consumers, and their families protected from the hazards of lead exposure.
				</p>
			</div>
		</ColumnContent>
	</div>
  	<HeaderOffset />
  </Layout>
)

export default CertifiedPage
